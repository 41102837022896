/**
 *
 * base
 *
 */
*
{
  box-sizing: border-box;
}

html
{
  font-size: 62.5%;//10px基準にする

  height: 100%;
}

body
{
  font-family: $font-web-sub;
  font-size: 1.531rem;
  line-height: 1.37;

  text-align: center;
  word-wrap: break-word;

  color: color(text);
  background: #f2f2f2;

  overflow-wrap: break-word;
  font-feature-settings: 'palt';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include mqup(lg)
  {
    font-size: 1.6rem;
  }
}

button,
input,
select,
textarea
{
  font: inherit;

  margin: 0;
}

optgroup
{
  font-weight: bold;
}

button,
input,
select
{
  overflow: visible;
}

button,
select
{
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit']
{
  cursor: pointer;
}

[disabled]
{
  cursor: default;
}

button,
html [type='button'],
[type='reset'],
[type='submit']
{
  -webkit-appearance: button;
}

button::-moz-focus-inner,
input::-moz-focus-inner
{
  padding: 0;

  border: 0;
}

button:-moz-focusring,
input:-moz-focusring
{
  outline: 1px dotted ButtonText;
}

button,
input,
select,
textarea
{
  border: none;
  outline: none;
}

button
{
  padding: 0;

  background: none;
}

*:focus
{
  outline: none;
}

ul,
ol
{
  list-style: none;
}

a
{
  transition: .3s ease;
  text-decoration: none;

  color: color(link);
  &:hover
  {
    text-decoration: none;
    //color: color(linkhover);
  }
}

.hide-text
{
  position: absolute;

  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  width: 1px;
  height: 1px;
}

%wf
{
  font-family: $font-web-base;
}
// %wf2 {
// 	font-family: $font-web-sub;
// }

