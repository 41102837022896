//**
// *
// * globalvars
// *
// */

//--------------------------------
// color
//--------------------------------
$color: (
	bg: #fff,
	bg-dark: #f7f7f7,
	bg-light: #007fc6,
	text: #58504b,
	text-dark: darken(#58504b, 15%),
	text-light: #007fc6,
	link: #58504b,
	linkhover: #886614,
	link2: #b69e6a,
	border: #d2d2d2,
	border-dark: #626262,
	border-light: #007fc6,
	brand: #007fc6,
	button: #fff,
	buttonhover: #007fc6,
	partition: #294c16, 
	red: #c80202,
	green: #094,
	green2: #00b400,
	blue: #1558a0,
	orange: #ffa800,
	orange2: #f17c1e,
	orange3: #ffa15a,
	facebook: #3b5998,
	twitter: #55acee,
	error: #ffa800,
);

//--------------------------------
// width
//--------------------------------
$width: (
	base: 1024px,
	wide: 1200px,
);

//--------------------------------
// space-size
//--------------------------------
$space: (
	base : 20px,
	small : 10px,
	wide : 40px,
	exwide : 80px,
	grid : 5px,
	lp: 200px,
);

//--------------------------------
// font-family
//--------------------------------

$font-serif: Georgia, 游明朝, 'Yu Mincho', YuMincho, 'Hiragino Mincho ProN', HGS明朝E, メイリオ, Meiryo, serif;
$font-sans-serif: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', '游ゴシック Medium', YuGothic,YuGothicM, 'Hiragino Kaku Gothic ProN', メイリオ, Meiryo, sans-serif;
$font-web-base: 'Marmelad', sans-serif;
$font-web-sub: 'Noto Sans JP', sans-serif;

//--------------------------------
// namespace
//--------------------------------
$ns: 'default' !default;

//--------------------------------
// image directry path
//--------------------------------
$images: '../images' !default;

