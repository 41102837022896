@charset 'utf-8';

@import 'base/globalvars';
@import "lib/mixins/_mixin.scss";

// ************************************
//
// reset & base
//
// *************************************/
@import 'base/reset';
@import 'base/fontface';
@import 'base/base';

// ************************************
//
// vender
//
// *************************************/
// @import 'lib/venders/icomoon';
// @import 'lib/venders/aos.scss';
// @import "lib/venders/slick";
// @import "lib/venders/slick-theme";
// @import "lib/venders/magnific-popup";
// @import "lib/venders/animate";
// @import "lib/venders/cssanimation";

// ************************************
//
// utility
//
// *************************************/
// @import 'base/utility';


// ***************************************
//
// style
//
// *************************************/
html
{
  width: 100%;
}
body
{
  width: 100%;
  height: 100%;

  background: #f2f2f2 url(../images/background_sp.jpg) no-repeat 50% 50%;
  background-attachment: fixed;
  background-size: cover;

  @include mqup(md)
  {
    background-image: url(../images/background.jpg);
  }
}

img
{
  display: block;

  width: 100%;
  height: auto;
}

.outline
{
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1824px;
  min-height: 100%;
  margin: 0 auto;

  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.container
{
  display: block;

  flex: 0 0 auto;
}

.article
{
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.logo
{
  display: block;

  //width: 54.4vw;
  width: 59.352vw;
  max-width: 371px;
  margin: 19.3vw auto 4vw;

  transform: translateX(8%);

  flex: 0 0 auto;
  @include mqup(md)
  {
    width: 33vw;
    max-width: 1113px;
    margin-top: 3.2vw;
    margin-bottom: 2.5vw;
  }
  @include mqup(wlg)
  {
    width: 22vw;
    min-width: 377px;
    margin-top: 5vw;
    margin-bottom: 1.6vw;
  }
}

.title
{
  display: block;

  //width: 79.926vw;
  width: 87.192vw;
  max-width: 545px;
  margin: 0 auto 16.53vw;

  flex: 0 0 auto;
  @include mqup(md)
  {
    width: 49.94vw;
    max-width: 1634px;
    margin-bottom: 6.3vw;
  }
  @include mqup(wlg)
  {
    width: 32.56vw;
    min-width: 571px;
    margin-bottom: 4.1vw;
  }
}

.headLine
{
  width: 66.13vw;
  margin: 0 auto 5.73vw;

  flex: 0 0 auto;
  @include mqdown(md)
  {
    max-width: 496px;
  }
  @include mqup(md)
  {
    width: 23vw;
    margin-bottom: 1.75vw;
  }
  @include mqup(wlg)
  {
    width: 15vw;
    min-width: 259px;
    margin-bottom: 1.14vw;
  }
  @include mqdown(wlg)
  {
    max-width: 276px;
  }
}

.text
{
  display: flex;
  flex-direction: column;

  margin: 0 auto;

  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  &_item
  {
    display: inline-block;

    width: 54.26vw;
    margin: 0 auto 5.73vw;

    @include mqdown(md)
    {
      max-width: 407px;
    }
    @include mqup(md)
    {
      width: 18vw;
      margin-bottom: 2vw;
    }
    @include mqup(wlg)
    {
      width: 11.8vw;
      min-width: 215px;
      margin-bottom: 1.358vw;
    }
    @include mqdown(wlg)
    {
      max-width: 300px;
    }
  }
}

.footer
{
  .mailto
  {
    position: relative;

    display: inline-block;

    margin: 7.3vw auto 9.3vw;

    @include mqup(md)
    {
      width: 13vw;
      margin-top: 2.5vw;
      margin-bottom: 5.75vw;
    }
    @include mqup(wlg)
    {
      width: 8.75vw;
      min-width: 146px;
      margin-top: 1.6vw;
      margin-bottom: 3.75vw;
    }
    @include mqdown(wlg)
    {
      max-width: 161px;
    }

    &::before,
    &::after
    {
      position: absolute;
      top: 0;
      left: 0;

      display: block;

      width: 100%;
      height: 100%;

      content: '';
      transition: opacity .3s ease 0s;

      background: no-repeat center center;
      background-size: 100% auto;
    }
    &::before
    {
      opacity: 1;
      background-image: url(../images/text_mail_default.svg);
    }
    &::after
    {
      opacity: 0;
      background-image: url(../images/text_mail_hover.svg);
    }
    &:hover
    {
      &::before
      {
        opacity: 0;
      }
      &::after
      {
        opacity: 1;
      }
    }
    img
    {
      visibility: hidden;

      width: 100%;

      opacity: 0;
    }
  }
}

.pc
{
  @include mqdown(md)
  {
    display: none !important;
  }
}

.sp
{
  @include mqup(md)
  {
    display: none !important;
  }
}

.msie
{
  display: none !important;
  @media all and (-ms-high-contrast:none)
  {
    display: block !important;
  }
}
.nomsie
{
  @media all and (-ms-high-contrast:none)
  {
    display: none !important;
  }
}
