//**
// *
// * font-face
// *
// */

//--------------------------------
// YuGothic
//--------------------------------
@font-face
{
  font-family: YuGothicM;

  src: local('Yu Gothic Medium');
}
//--------------------------------
// 源ノ角ゴシック(Noto Sans)
//--------------------------------
//@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
//--------------------------------
// 源ノ明朝(Noto Serif)
//--------------------------------
//@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,700);
//--------------------------------
// Garamond
//--------------------------------
//@import url(https://fonts.googleapis.com/css?family=EB+Garamond:600);

//--------------------------------
// Noto
//--------------------------------
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700,900&|Noto+Serif+JP&display=swap');

//--------------------------------
// Roboto
//--------------------------------
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
